import { render, staticRenderFns } from "./PaginaInicio.vue?vue&type=template&id=bcdd4f52&scoped=true"
import script from "./PaginaInicio.vue?vue&type=script&setup=true&lang=js"
export * from "./PaginaInicio.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PaginaInicio.vue?vue&type=style&index=0&id=bcdd4f52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcdd4f52",
  null
  
)

export default component.exports