var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{attrs:{"id":"principal"}},[_c('div',{staticClass:"contenedor-portada"},[_c('video',{staticClass:"portada",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_setup.cdn_archivos_dominio}/portada/agua-${
          _setup.esMovil ? 'movil' : 'escritorio'
        }.webm`,"type":"video/webm"}})]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"contenedor ancho-fijo m-y-10"},[_c('div',{staticClass:"flex flex-contenido-separado flex-columnas-100-al-simplificar"},[_c('div',{staticClass:"columna-16 m-t-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo tarjeta-eni",attrs:{"href":`${_setup.domain_tox}${_setup.base_route}resa-atoyac`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/resa-esc.png`}})]),_vm._m(2)])]),_c('div',{staticClass:"columna-16 m-t-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo tarjeta-eni",attrs:{"href":`${_setup.domain}${_setup.base_route}cuencas`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`./capitulos/cuencas.jpg`}})]),_vm._m(3)])]),_c('div',{staticClass:"columna-8 m-t-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.url_gema}/visualizador?seccion=agua`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{staticClass:"fondo-imagen",attrs:{"src":`${_setup.cdn_archivos_dominio}/tarjetas/eni-agua-gema.png`}})]),_vm._m(4)])]),_c('div',{staticClass:"columna-8 m-t-3"},[_c('a',{staticClass:"tarjeta-hipervinculo-externo",attrs:{"href":`${_setup.ambiente_eni}/radiombligo?tematica=Agua`,"target":"_blank","rel":"noopener noreferrer"}},[_c('picture',{staticClass:"fondo-imagen"},[_c('img',{attrs:{"src":`${_setup.cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`}})]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('div',{staticClass:"contenedor ancho-lectura"},[_c('h1',{staticClass:"titulo-eni texto-centrado texto-color-1"},[_vm._v("Agua")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contenedor ancho-lectura m-y-10"},[_c('h2',{staticClass:"texto-centrado m-y-8-esc m-y-4-mov m-x-auto"},[_vm._v(" Ecosistema Nacional Informático de Agua ")]),_c('p',{staticClass:"texto-alto m-y-8-esc m-y-4-mov"},[_vm._v(" El Ecosistema Nacional Informático de Agua ofrece un espacio de acceso abierto para el análisis de datos y visualización de información generada en el marco del Programa Nacional Estratégico de Agua. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Región de Emergencia Sanitaria y Ambiental: Cuenca del Alto Atoyac ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Agua y Cuencas en México ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Explora capas del tema agua ")]),_c('hr'),_c('p',{staticClass:"subtitulo-capitulo texto-color-1"},[_vm._v(" Gestor de mapas (Gema) ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"degradado-negro-abajo"},[_c('div',{staticClass:"contenido-abajo"},[_c('p',{staticClass:"titulo-pagina texto-color-1"},[_vm._v(" Ciencia para niñas y niños ")])])])
}]

export { render, staticRenderFns }