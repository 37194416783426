<script setup>
import { onBeforeUnmount, onMounted, computed } from 'vue'

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})

const esMovil = computed(() => Boolean(window.innerWidth < 768))

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const domain = process.env.VUE_APP_ENI_DOMAIN
const domain_tox = process.env.VUE_APP_ENI_DOMAIN_TOX
const url_gema = process.env.VUE_APP_GEMA
const base_route = process.env.VUE_APP_BASE_ROUTE
const ambiente_eni = process.env.VUE_APP_ENI_LDN
const cdn_archivos_ldn = cdn_archivos_dominio.replace('/agua/index', '/landing')
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <video
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/agua-${
            esMovil ? 'movil' : 'escritorio'
          }.webm`"
          type="video/webm"
        />
      </video>

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">Agua</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de Agua
      </h2>
      <p class="texto-alto m-y-8-esc m-y-4-mov">
        El Ecosistema Nacional Informático de Agua ofrece un espacio de acceso
        abierto para el análisis de datos y visualización de información
        generada en el marco del Programa Nacional Estratégico de Agua.
      </p>
    </div>
    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado flex-columnas-100-al-simplificar"
      >
        <div class="columna-16 m-t-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${domain_tox}${base_route}resa-atoyac`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/resa-esc.png`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Región de Emergencia Sanitaria y Ambiental: Cuenca del Alto
                  Atoyac
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-16 m-t-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${domain}${base_route}cuencas`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`./capitulos/cuencas.jpg`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Agua y Cuencas en México
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8 m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${url_gema}/visualizador?seccion=agua`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/eni-agua-gema.png`"
                class="fondo-imagen"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora capas del tema agua
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8 m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${ambiente_eni}/radiombligo?tematica=Agua`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`"
              />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Ciencia para niñas y niños
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
    }
  }
  .degradado-negro-abajo {
    .titulo-pagina {
      color: #ffffff;
    }
  }
}
</style>
